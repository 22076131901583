import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { Link, useHistory } from "react-router-dom";

import QuestionsImg from "../assets/questions.png"

import {
  auth,
  registerWithEmailAndPassword,
  registerInWithGoogle,
} from "../firebase";

function Register() {


  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);


  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  const [ locationKeys, setLocationKeys ] = useState([])
  const questions =  [
     { 
        button: "Empecemos",
        title: "Bienvenido a Theraphya",
        subtitle:"Empezaremos haciendoté unas preguntas sobre tu estado mental",
        img: QuestionsImg,
      },
     
      { 
        button: "Siguiente",
        title: "¿Has notado cambios en tu nivel de energía, motivación o calidad de sueño y descanso debido al estrés?",
        answers: ["Si","Parcialmente","No"],
      },
      // 3
       { 
        button: "Siguiente",
        title: "¿Has observado alteraciones en tu comportamiento o dificultades en la concentración como resultado de la ansiedad?",
        answers: ["Muy frecuentemente","Frecuentemente","A veces","Nunca"],
      },
       { 
        button: "Siguiente",
        title: "¿Experimentas cambios bruscos en tu estado de ánimo, o episodios de tristeza profunda e irritabilidad constante?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Hay aspectos específicos de tu apariencia o personalidad que te generan preocupación?",
        answers: ["Muy frecuentemente","Frecuentemente","A veces","Nunca"],
      },
       { 
        button: "Siguiente",
        title: "¿Te sientes agotado/a incluso después de descansar adecuadamente?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has vivido situaciones en las que hayas sido víctima de violencia de género?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Te has encontrado en situaciones donde te sientes manipulado/a o abusado/a emocionalmente?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has experimentado situaciones de acoso verbal, físico o en línea?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has tenido pensamientos sobre hacerte daño a ti mismo/a o sobre el suicidio? ¿con que frecuencia e intensidad?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has experimentado alguna vez ansiedad persistente vinculada a recuerdos angustiantes",
        answers: ["Si","No"],
      }
      ,
       { 
        button: "Siguiente",
        title: "¿Te sientes inseguro/a e incómodo/a contigo mismo/a?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has sentido alguna vez que tu identidad de género o tu orientación sexual ha sido motivo de discriminación, acoso o falta de comprensión?",
        answers: ["Si","No"],
      },
    
       { 
        button: "Siguiente",
        title: "¿Hay expectativas de género externas que te causan malestar o ansiedad?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has notado patrones de comportamiento en las personas cercanas a ti que te hacen sentir menospreciado/a o desvalorizado/a?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Existen eventos en tu pasado que consideres traumáticos y que hayan tenido un impacto negativo en tu vida?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has experimentado alteraciones significativas en tu patrón de sueño, como insomnio o fatiga constante?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Experimentas obsesiones incontrolables que condicionan tu comportamiento? ¿Te sientes impulsado/a a realizar comportamientos repetitivos en respuesta a tus obsesiones?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has experimentado o estás experimentando patrones alimenticios que se alejan de lo considerado como normativo, como restricciones extremas o episodios de alimentación compulsiva?",
        answers: ["Si","No"],
      },
      { 
       button: "Siguiente",
       title: "¿Has sentido alguna vez malestar o insatisfacción significativa con tu propio cuerpo?",
       answers: ["Si","No"],
     },
       { 
        button: "Siguiente",
        title: "¿Sientes que tu relación con amigos, seres queridos o pareja no alcanza el nivel de calidad que debería?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Hay hábitos que te gustaría cambiar, pero te resulta difícil? ¿Te encuentras tomando decisiones que sabes que son perjudiciales para ti?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Has sentido o sientes la carga emocional y física de lidiar con una enfermedad crónica?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Experimentas la sensación de que la vida es injusta y carece de sentido alguno?",
        answers: ["Si","No"],
      },
       { 
        button: "Siguiente",
        title: "¿Te sientes desmotivado o carente de propósitos u objetivos que te apasionen?",
        answers: ["Si","No"],
      },
     
      { 
        button: "Siguiente",
        title: "¿Cómo te llamas?",
        subtitle:"Por último escribe tu nombre",
        functionA: (e) => setName(e),
        value: name,
        input:"Tu nombre",
        type: "input",
      }
      ];

  const register = () => {
    registerWithEmailAndPassword(email.toLowerCase(), password,name,answers);
  };  

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
  
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
  
          setStep(++step)
  
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
  
          setStep(--step)
  
        }
      }
    })
  }, [ locationKeys, ])


  function LoadUser() {

    if (loading) {
      return;
    }
    if (user) { history.replace("/cuenta")  } 
  }

  useEffect(() => {
    LoadUser()
  }, [user, loading]);


  function RegisterForm()    {
      return (
            <div className="RegisterForm slide-in-blurred-left ">
                <div className="container p-2">
                <h2 className="mb-0">Por último crea tu cuenta
                </h2>
                <p className="mb-0">Empieza con tu camino ahora mismo</p>

                {/* Social buttons */}
                          
                {/* Form START */}
                <form>
                  {/* Email */}
                  <div className="input-floating-label form-floating mb-4">
                    <input type="email"
                           className="form-control mt-5"
                           id="floatingInput"
                           placeholder="name@example.com"
                           value={email}
                          onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Dirección email</label>
                  </div>

                  {/* Password */}
                  <div className="input-floating-label form-floating position-relative">
                    <input 
                        className="form-control fakepassword pe-6" id="psw-input"
                        placeholder="Enter your password"
                        value={password}
                         type={
                            showPassword ? "text" : "password"
                         }
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    <label htmlFor="floatingInput">Contraseña</label>
                    <span className="position-absolute top-50 end-0 translate-middle-y p-0 me-2">
                      <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" 
                      onClick={() =>
                          setShowPassword(!showPassword)
                      }
                      ></i>
                    </span>
                  </div>
                  {/* Pswmeter */}

                  <div className="mb-4 mt-3">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="checkbox-1"/>
                      <label className="form-check-label" htmlFor="checkbox-1" ><Link> Aceptas los términos de condiciones</Link> y <a href="https://theraphya.com/terms" target="__blank">políticas de privacidad </a></label>
                    </div>
                  </div>
                  {/* Button */}
                  <div className="align-items-center mt-0">
                    <div className="d-grid">
                      <button className="btn btn-primary mb-0" type="button" onClick={() => register()}>Crea tu cuenta</button>
                    </div>
                  </div>
                </form>

                  <div id="pswmeter" className="mt-3"></div>
                  
            
                  <div className="position-relative my-5">
                    <hr/>
                    <p className="small position-absolute top-50 start-50 translate-middle bg-body px-4">Or</p>
                  </div>

                      <div className="row mt-2 mb-3">
                  {/* Social btn */}
                  <div className="col-xl-12 d-grid">
                    <a onClick={() => registerInWithGoogle(answers,name)} className="btn border-primary text-primary bg-white mb-2 mb-xxl-0"><i className="fab fa-fw fa-google text-google-icon me-2"></i>Inicia con Google</a>
                  </div>
                  
                  </div>
                  </div>
                </div>
        )
  }


  function nextQuestion(titleQ,AnswQ) {

    setStep(step+1)
    setAnswers([
      ...answers,
      { title: titleQ , answer: AnswQ || "" }
    ]);
  
    // console.log(answers)
  }


  function questionCard(item) {
    return (
        <div  className="m-3  mt-0 slide-in-blurred-left ">
            <h2 className="mb-0">{item?.title}</h2>
            <p className="mb-0">{item?.subtitle}</p>
            
            {item?.img != undefined ? <img src={item.img} className="img-fluid" /> :null}
            
            {item?.type == "input" ? 

            <>
                   <div className="input-floating-label form-floating mb-1 mt-0">
                    <input type="string"
                           className="form-control mt-3"
                           id="floatingInput"
                           placeholder="Nombre "
                           value={item.value}
                           onChange={(e) => item?.functionA(e.target.value)}
                    />
                    <label htmlFor="floatingInput">{item?.input}</label>
                  </div>
            </>
            : null}

            <div className="row mt-4">
            { 
              item?.answers?.map((itemAns, idx) =>
                <button key={idx} type="button" className="btn btn-outline-primary btn-lg m-1 p-2 shadow-sm" onClick={() =>nextQuestion(item.title,itemAns)}>{itemAns}</button>
              )
            }
             </div>
           
             <div className="align-items-center mt-0 justify-content-bottom mt-5">
                  <div className="d-grid">
                    <button className="btn btn-primary mb-0" onClick={() =>nextQuestion(item.title,"")} type="button" >{item?.button}</button>
                    { step > 1 ? <a className="link mt-1 cursor" onClick={ () => setStep(step-1)}><i className="fa fa-back-arrow text-primary"></i> Volver</a> : null}
                  </div>
              </div>
        </div>

      )

  }

  function renderCards() {
        return questionCard(questions[step]);
  }

  return(
      <div className="">
      <main>
        <div className="row g-0">
          {/* left */}
          <div className="col-sm-10 col-lg-5 d-flex m-auto vh-100">
            <div className="row w-100 m-auto">
              <div className="col-lg-10 my-2 m-auto">

              <div className="RegisterForm">
               
             
         
              {   RegisterForm()}

                                 
                </div>

      
                {/* Sign IN link */}
                <div className="mt-4 text-center">
                  <span>¿Ya tienes una cuenta? <Link to="/login"> Inicia sesión </Link></span>
                </div>
                
              </div>
            </div>
          </div>

           <div className="col-lg-7 mt-4" >
            {/* Slider START */}
            <div className="vh-100  roundImg "  style={{backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}>
            <div className="d-flex flex-column justify-content-center h-100 container">
                    
              <div className="p-3">
              <h1 className="text-light italic">Si quieres ir rápido ve solo, si quieres llegar lejos ve acompañado.</h1>

              <div className="d-flex justify-content-between mt-5">
                <div>
                  <h5 className="mb-0 fs-2 text-light ">- Proverbio africano</h5>
                </div>

             
              </div>
            </div>
              </div>

            </div>
            </div>
              </div>
    </main>

    </div>

  )
}


export default Register;
