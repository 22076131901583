import React, { useState } from 'react';
import Chart from 'react-apexcharts';

import Award from "../assets/trophy.png"


import ConfettiExplosion from 'react-confetti-explosion';
import toast from 'react-hot-toast';
import { registerInWithGoogle } from '../firebase';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const FormularioEvaluacion = () => {
  const [respuestas, setRespuestas] = useState({});
  const [preguntaActual, setPreguntaActual] = useState(0);
  const [mostrarGrafico, setMostrarGrafico] = useState(false);

  const preguntas = [
    { dimension: "Estrés", texto: "¿Has notado cambios en tu nivel de energía, motivación o calidad de sueño y descanso debido al estrés?" },
    { dimension: "Ansiedad", texto: "¿Has observado alteraciones en tu comportamiento o dificultades en la concentración como resultado de la ansiedad?" },
    { dimension: "Carencia de bienestar", texto: "¿Hay aspectos específicos de tu apariencia o personalidad que te generan preocupación?" },
    { dimension: "Estrés", texto: "¿Te sientes agotado/a incluso después de descansar adecuadamente?" },
    { dimension: "Dificultades sociales", texto: "¿Te has encontrado en situaciones donde te has sentido manipulado/a o abusado/a emocionalmente?" },
    { dimension: "Depresión", texto: "¿Has tenido pensamientos sobre hacerte daño a ti mismo/a o sobre el suicidio? ¿con qué frecuencia e intensidad?" },
    { dimension: "Depresión", texto: "¿Experimentas cambios bruscos en tu estado de ánimo, o episodios de tristeza profunda e irritabilidad constante?" },
    { dimension: "Ansiedad", texto: "¿Te sientes inseguro/a e incómodo/a contigo mismo/a?" },
    { dimension: "Ansiedad", texto: "¿Hay expectativas de género externas que te causan malestar o ansiedad?" },
    { dimension: "Dificultades sociales", texto: "¿Has notado patrones de comportamiento en las personas cercanas a ti que te hacen sentir menospreciado/a o desvalorizado/a?" },
    { dimension: "Carencia de bienestar", texto: "¿Existen eventos en tu pasado que consideres traumáticos y que hayan tenido un impacto negativo en tu vida?" },
    { dimension: "Ansiedad", texto: "¿Te has sentido impulsado/a a realizar comportamientos repetitivos o compulsivos en respuesta a tus obsesiones?" },
    { dimension: "Carencia de bienestar", texto: "¿Has sentido alguna vez malestar o insatisfacción significativa con tu propio cuerpo?" },
    { dimension: "Dificultades sociales", texto: "¿Sientes que tu relación con amigos, seres queridos o pareja alcanza un nivel de calidad con el que te sientes cómodo?" },
    { dimension: "Carencia de bienestar", texto: "¿Has sentido o sientes la carga emocional y física de lidiar con una enfermedad crónica?" }
  ];

  const opciones = [
    { text: "Nunca", color: "#B2E6D4" },       // Verde agua suave
    { text: "Rara vez", color: "#D7F2BA" },    // Verde menta claro
    { text: "A veces", color: "#FFF2B2" },     // Amarillo pastel
    { text: "Frecuentemente", color: "#FFDDAA" }, // Durazno suave
    { text: "Siempre", color: "#FFCCCC" }      // Rosa claro
  ];
  
  const handleAnswer = (value) => {
    setRespuestas({
      ...respuestas,
      [preguntas[preguntaActual].texto]: parseInt(value)
    });

    if (preguntaActual < preguntas.length - 1) {
      setPreguntaActual(preguntaActual + 1);
    } else {
      setMostrarGrafico(true);
      toast.success(`Desbloqueado trofeo`)
    }
  };

  const calcularPuntuacion = (dimension) => {
    const puntuacion = preguntas
      .filter(p => p.dimension === dimension)
      .map(p => respuestas[p.texto] || 0)
      .reduce((acc, curr) => acc + curr, 0);
    return puntuacion / preguntas.filter(p => p.dimension === dimension).length;
  };

  const chartData = {
    series: [{
      name: 'Evaluación de bienestar',
      data: [
        calcularPuntuacion("Ansiedad"),
        calcularPuntuacion("Estrés"),
        calcularPuntuacion("Carencia de bienestar"),
        calcularPuntuacion("Dificultades sociales"),
        calcularPuntuacion("Depresión")
      ]
    }],
    options: {
      chart: {
        type: 'radar'
      },
      title: {
        text: 'Resultados de la Evaluación de Bienestar'
      },
      xaxis: {
        categories: ["Ansiedad", "Estrés", "Carencia de bienestar", "Dificultades sociales", "Depresión"]
      },
      yaxis: {
        max: 5,
        labels: {
          formatter: val => val.toFixed(1)
        }
      }
    }
  };

  return (
    <div className="">
    <main className='magicpattern '>
      <div className="row g-0 mt-5 slide-in-blurred-left">

        {/* left */}
    <div className="col-sm-10 col-lg-8 d-flex m-auto  mt-5 ">

    

    <div className="row w-100 m-auto mb-5">
    <div className="col-lg-10 my-2 m-auto mb-5">
    <div>
      {!mostrarGrafico ? (
        <div>

          <h2 className="mb-0">{preguntas[preguntaActual].texto}</h2>
          <p className="mb-0">Pregunta {preguntaActual + 1} de {preguntas.length}</p>
          <div  className='d-flex aling-items-center justify-content-center'>
          <div className='row mt-5 co-12 col-md-6'>
            {opciones.map((opcion, index) => (
             <button  key={index} onClick={() => handleAnswer(index + 1)} type="button" style={{backgroundColor: opcion.color, }} className="btn text-dark btn-rounded m-1 p-3 shadow-sm mt-1 mb-2">{opcion.text}</button>
            ))}
          </div>
          </div>

        </div>
      ) : (
        <>
        <div className=" card  shadow mb-5">
            <div className="container">
              <div className='m-3 text-center'>
              <h2 className='text-primary'>¡Test terminado!</h2>
              <h6 className='text-grey'>Enorabuena has desbloqueado el primer paso a la salud mental con terapia</h6>
              <ConfettiExplosion />
              <img className='img-fluid firstAward slide-in-blurred-left' src={Award}/>

              </div>
              <div  className='row align-items-center text-center justify-content-center'>
                <a onClick={() => registerInWithGoogle([])} className="btn btn-primary border-primary text-primary bg-white mt-3 col-12 col-md-6"><i className="fab fa-fw fa-google text-google-icon "></i>Registrate con Google</a>
                <div/>
                <Link to="register" className="btn btn-primary text-white text-primary  mt-3 col-12 col-md-6 ">Inicia con email</Link>  
            </div>

            </div>
           
        
            <br/>
            <br/>
            <br/>
            <br/>

        </div>
        
        </>
        // <Chart options={chartData.options} series={chartData.series} type="radar" width="500" />
      )}
    </div>
    </div>
    </div>
    </div>
    </div>

  </main>
  </div>

  );
};

export default FormularioEvaluacion;
