import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import './Theme.css';

import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Reset from "./Auth/Reset";
import Landing from "./Main/Landing";
import Profile from "./Main/Profile";
import Nosotros from "./Main/Nosotros";
import Blog from "./Main/Blog";
import Article from "./Main/Article";

import Precios from "./Components/Precios";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop"
import NavbarComponent from "./Components/Navbar";



import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { Toaster } from 'react-hot-toast';


import { auth , db} from "./firebase"
import { useEffect } from "react";

import Terms from "./Main/Terms";
import ProfileTherapists from "./Main/ProfileTherapist";
import Pago from "./Components/Pago";
import TiposTerapia from "./Main/TipoTerapias";
import Preguntas from "./Main/Preguntas";
import FlarumEmbed from "./Main/Comunity";



const stripePromise = loadStripe('pk_live_51OWGVKGs4sg3xpWot0AU4IBpMtQj5a3At14WLs9kiVOEqMweQwQHMBFQYGlt5vcPYzJQ85nhpvUgvumpgbVDjDdi00FEguj4UD');

function App() {

  useEffect(() => {
    setTimeout(() => {
      if(auth.currentUser === true ) {db.collection("users").doc(auth.currentUser.uid).update({lastStatus: Date.now()})} }, 3000);
  }, [])
  
  return (

    <div className="app">
      <Toaster></Toaster>
      <Elements stripe={stripePromise}>
      <Router>
      <ScrollToTop/>

      <NavbarComponent/>
        <Switch>

          <Route exact path="/" component={Landing} />   
          <Route exact path="/cuenta" component={Profile} />        
          <Route exact path="/cuenta-terapeuta" component={ProfileTherapists} />

          <Route exact path="/precios" component={Precios} />    
          <Route exact path="/pago" component={Pago} />    


          <Route exact path="/nosotros" component={Nosotros} />   
          <Route exact path="/blog" component={Blog} />   
          <Route exact path="/article/:id" component={Article} />   


          <Route exact path="/register" component={Register}  />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/login" component={Login} />

          <Route exact path="/terapia/:id" component={TiposTerapia} />
          <Route exact path="/preguntas" component={Preguntas} />


          {/* <Route exact path="/comunity" component={FlarumEmbed} /> */}





        </Switch>
        <Footer/>
      </Router>
      </Elements>
    </div>
  );
}

export default App;
